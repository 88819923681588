import { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { LandingStyle } from './StyleLanding';
import Facebook from '../images/facebook.png';
import Discord from '../components/buttons/discord';
import InstagramButton from '../components/buttons/intsgram';
import Register from '../components/forms/register';
import Login from '../components/forms/Login';
import Footer from '../components/footer/Footer';
import PublicVlog from '../components/publicNews/PublicVlog';
import Header from '../components/header/Header';
import SliderComponent from '../components/slider/SliderComponent';
import { useAppSelector } from '../redux/actions';
import Typewriter from 'typewriter-effect';
import CardsPublic from '../components/CardsPublic/CardsPublic';
import usePrograms from '../hooks/dataPrograms';
import LogoVsCode from '../components/vscode/Vscode';
import Cookies from '../components/Cookies/cookies';
import { useCookies } from 'react-cookie';

import { Helmet } from 'react-helmet';

const LandingPage = () => {
  const isConnected = useAppSelector(
    (state) => state.logUser.value?.user?.stsTokenManager?.accessToken
  );

  const [changeLog, setChangeLog] = useState(false);
  const [display, setDisplay] = useState(false);
  const [displayRegister, setDisplayRegister] = useState(false);
  const [cookies] = useCookies(['cookieConsent']);

  const { api } = usePrograms();
  const textureDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = textureDivRef.current;
    const specklesCount = 500; // Number of speckles to generate

    if (container) {
      for (let i = 0; i < specklesCount; i++) {
        const speck = document.createElement('div');
        const size = Math.random() * 4 + 1; // Random size between 1px and 5px
        const xPos = Math.random() * container.offsetWidth;
        const yPos = Math.random() * container.offsetHeight;

        speck.className = 'speck';
        speck.style.width = `${size}px`;
        speck.style.height = `${size}px`;
        speck.style.left = `${xPos}px`;
        speck.style.top = `${yPos}px`;

        container.appendChild(speck);
      }
    }
  }, []);

  useEffect(() => {
    // Do something if needed
  }, [changeLog]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WHITEHAT4U.COM LEARN ONLINE</title>
        <title>WHITEHAT4U.COM: LEARN ONLINE</title>
        <meta
          name="description"
          content="Dive into the world of technology learning with our comprehensive online resources. Explore programming and Logic"
        />

        <meta property="og:url" content="https://www.whitehat4u.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="WHITEHAT4U.COM: LEARN ONLINE" />
        <meta
          property="og:description"
          content="Dive into the world of technology learning with our comprehensive online resources. Explore programming and Logic"
        />
        <meta
          property="og:image"
          content="https://www.whitehat4u.com/whitehat4u.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="whitehat4u.com" />
        <meta property="twitter:url" content="https://www.whitehat4u.com" />
        <meta name="twitter:title" content="WHITEHAT4U.COM: LEARN ONLINE" />
        <meta
          name="twitter:description"
          content="Dive into the world of technology learning with our comprehensive online resources. Explore programming and Logic"
        />
        <meta
          name="twitter:image"
          content="https://www.whitehat4u.com/whitehat4u.jpg"
        />
      </Helmet>

      {isConnected ? (
        <Navigate to="/dashboard" />
      ) : (
        <LandingStyle id="textureDiv" ref={textureDivRef}>
          {!cookies.cookieConsent && <Cookies />}
          <Header />
          <main className="main">
            <div className="main__legend">
              <div className="main__legend--image">
                <img
                  src="/juan.png"
                  alt="juan"
                  fetchpriority="high"
                  loading="lazy"
                />
              </div>

              <div className="sliderView">
                <SliderComponent />
              </div>
            </div>

            <section className="order-1">
              <div>
                <ul className="control-button">
                  <li
                    className="control-button__login"
                    onClick={() => {
                      setChangeLog(!changeLog);
                      setDisplay(true);
                      setDisplayRegister(false);
                    }}>
                    Login
                  </li>

                  <li
                    className="control-button__register"
                    onClick={() => {
                      setChangeLog(!changeLog);
                      setDisplay(false);
                      setDisplayRegister(true);
                    }}>
                    Register
                  </li>
                </ul>
              </div>

              <div className={`display ${!changeLog && 'hidden'} `}>
                <p className="facebook">
                  <img
                    src={Facebook}
                    alt="something"
                    loading="lazy"
                    style={{ width: '20px', height: '20px' }}
                  />
                  {!display ? 'Register' : 'Login'}
                </p>

                {displayRegister ? <Register /> : <Login />}
              </div>
              {cookies.cookieConsent && (
                <>
                  <div className="findUs">
                    <h3>
                      <span> Find us only at:</span>
                    </h3>
                  </div>
                  <div className="main-external">
                    <a href="https://discord.gg/dafktC8SZR">
                      <Discord />
                    </a>

                    <a href="https://www.instagram.com/whitehat4u/?hl=en">
                      <InstagramButton />
                    </a>
                  </div>{' '}
                </>
              )}
            </section>

            <section className="section-slider">
              <div className="containerjuan">
                <div className="containerjuan__juan">
                  <img
                    src="/juan.png"
                    alt="juan"
                    fetchpriority="high"
                    loading="lazy"
                  />
                </div>
                <SliderComponent />
              </div>
            </section>
          </main>

          <section className="sectionPrograms">
            <h2 className="labelText">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString('Programs')
                    .callFunction(() => {
                      console.log('wh4u!');
                    })
                    .start()
                    .typeString('<BR/>Online');
                }}
              />
            </h2>
            <article className="sectionPrograms__items">
              {api?.programs?.length !== undefined ? (
                <>
                  {api?.programs.map((item) => {
                    return (
                      <CardsPublic
                        key={item.id}
                        title={item.title}
                        content={item.content}
                        image={item.image}
                        language={item.language}
                        price={item.price}
                        external={item.external}
                      />
                    );
                  })}
                </>
              ) : null}
            </article>
          </section>

          <section className="sectionVsCode">
            <LogoVsCode />

            <div className="main-external-footer">
              <a href="https://discord.gg/dafktC8SZR">
                <Discord />
              </a>

              <a href="https://www.instagram.com/whitehat4u/?hl=en">
                <InstagramButton />
              </a>
            </div>
          </section>

          <section className="sectionVlog">
            <h2 className="labelText">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString('The')
                    .callFunction(() => {
                      console.log('wh4u!');
                    })
                    .start()
                    .typeString(' <BR/>News');
                }}
              />
            </h2>
            <PublicVlog />
          </section>

          <Footer />
        </LandingStyle>
      )}
    </>
  );
};

export default LandingPage;
